import React, { useState, useEffect } from 'react';
import './App.css';
import Header from './components/Header';
import Counter from './components/Counter';
import NavButtons from './components/NavButtons';
import BoosterPage from './components/BoosterPage';
import MinersPage from './components/MinersPage';
import ProfileScreen from './components/ProfileScreen';
import Login from './components/Login';
import axios from 'axios';

// Define types for dynamic keys
interface Boosters {
  [key: string]: boolean;
}

interface Miners {
  [key: string]: boolean;  // Miners as boolean to align with ProfileScreen
}

interface UserData {
  tapCount: number;
  boosters: Boosters;
  miners: Miners;
}

const App: React.FC = () => {
  const [activeScreen, setActiveScreen] = useState<string>('Home');
  const [username, setUsername] = useState<string | null>(null);
  const [userData, setUserData] = useState<UserData | null>(null);

  useEffect(() => {
    const savedUsername = localStorage.getItem('username');
    if (savedUsername) {
      setUsername(savedUsername);
      fetchUserData(savedUsername);
    }
  }, []);

  useEffect(() => {
    if (username && userData) {
      saveUserData();
    }
  }, [userData, username]);

  const fetchUserData = async (username: string) => {
    try {
      const response = await axios.get(`http://localhost:5000/user/${username}`, { params: { _: new Date().getTime() } });
      setUserData(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const saveUserData = async () => {
    if (!username || !userData) return;

    try {
      await axios.put(`http://localhost:5000/user/${username}`, userData, { params: { _: new Date().getTime() } });
    } catch (error) {
      console.error('Error saving user data:', error);
    }
  };

  const handleNavigate = (screen: string) => {
    setActiveScreen(screen);
  };

  const handleBack = () => {
    setActiveScreen('Home');
  };

  const handleBuyBooster = (level: string, price: number) => {
    if (userData && userData.tapCount >= price) {
      setUserData({
        ...userData,
        boosters: { ...userData.boosters, [level]: true },
        tapCount: userData.tapCount - price,
      });
    }
  };

  const handleBuyMiner = (level: string, price: number) => {
    if (!userData) return;

    if (userData.tapCount < price) {
      alert('Insufficient tap count to buy this miner.');
      return;
    }

    if (!canBuyMiner(level)) {
      alert('You must buy lower level miners first.');
      return;
    }

    setUserData({
      ...userData,
      miners: { ...userData.miners, [level]: true }, // Update to boolean
      tapCount: userData.tapCount - price,
    });
  };

  const canBuyMiner = (level: string) => {
    if (!userData) return false;

    const levels = ['2x', '3x', '5x', '10x', '20x'];
    const index = levels.indexOf(level);

    return levels.slice(0, index).every(l => userData.miners[l]);
  };

  const handleTap = () => {
    if (!userData) return;

    const boosters: { [key: string]: number } = {
      '2x': 2,
      '3x': 3,
      '5x': 5,
      '10x': 10,
      '20x': 20,
    };

    // Calculate the multiplier
    const multiplier = Object.keys(boosters).reduce((acc, key) => {
      return userData.boosters[key] ? acc + boosters[key] : acc;
    }, 1);

    setUserData((prevData) => ({
      ...prevData!,
      tapCount: parseFloat((prevData!.tapCount + multiplier).toFixed(2)),
    }));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!userData) return;

      const autoTapIncrement = Object.keys(userData.miners).reduce((acc, key) => {
        return userData.miners[key] ? acc + 1 : acc; // Increment by 1 if miner is owned
      }, 0);

      setUserData((prevData) => ({
        ...prevData!,
        tapCount: parseFloat((prevData!.tapCount + autoTapIncrement).toFixed(2)),
      }));
    }, 1000);

    return () => clearInterval(interval);
  }, [userData]);

  const handleLogin = async (username: string) => {
    try {
      const response = await axios.post('http://localhost:5000/login', { username }, { params: { _: new Date().getTime() } });
      localStorage.setItem('username', username);
      setUsername(username);
      setUserData(response.data);
    } catch (error) {
      console.error('Error logging in:', error);
    }
  };

  const renderContent = () => {
    if (!userData) return null;

    switch (activeScreen) {
      case 'Invite':
        return <div>Invite Screen</div>;
      case 'Home':
        return <Counter tapCount={userData.tapCount} onIncrement={handleTap} />;
      case 'Booster':
        return (
          <BoosterPage
            tapCount={userData.tapCount}
            boosters={userData.boosters}
            onBuyBooster={handleBuyBooster}
          />
        );
      case 'Miners':
        return (
          <MinersPage
            tapCount={userData.tapCount}
            miners={userData.miners}
            onBuyMiner={handleBuyMiner}
          />
        );
      case 'Profile':
        return (
          <ProfileScreen
            username={username!}
            tapCount={userData.tapCount}
            boosters={userData.boosters}
            miners={userData.miners}
          />
        );
      case 'DailyTasks':
        return <div>Daily Tasks Screen</div>;
      default:
        return <Counter tapCount={userData.tapCount} onIncrement={handleTap} />;
    }
  };

  return (
    <div className="App">
      {username ? (
        <>
          <Header
            title={activeScreen}
            showBackButton={activeScreen !== 'Home'}
            onBack={handleBack}
          />
          <div className="content">
            {renderContent()}
          </div>
          <NavButtons onNavigate={handleNavigate} username={username!} />
        </>
      ) : (
        <Login onLogin={handleLogin} />
      )}
    </div>
  );
};

export default App;
