import React from 'react';
import './Header.css';

interface HeaderProps {
    title: string;
    showBackButton: boolean;
    onBack: () => void;
}

const Header: React.FC<HeaderProps> = ({ title, showBackButton, onBack }) => {
    return (
        <header className="header">
            {showBackButton && <button className="back-button" onClick={onBack}>Back</button>}
            <h1 className="header-title">{title}</h1>
        </header>
    );
};

export default Header;
