import React from 'react';
import './MinersPage.css';

interface MinersPageProps {
    tapCount: number;
    miners: { [key: string]: boolean };
    onBuyMiner: (level: string, price: number) => void;
}

const MinersPage: React.FC<MinersPageProps> = ({ tapCount, miners, onBuyMiner }) => {
    const minerData = [
        { level: '2x', price: 20 },
        { level: '3x', price: 30 },
        { level: '5x', price: 50 },
        { level: '10x', price: 100 },
        { level: '20x', price: 200 },
    ];

    const handleBuyClick = (level: string, price: number) => {
        if (tapCount < price) {
            alert('Insufficient tap count to buy this miner.');
            return;
        }

        if (!canBuyMiner(level)) {
            alert('You must buy lower level miners first.');
            return;
        }

        onBuyMiner(level, price);
    };

    const canBuyMiner = (level: string) => {
        const levels = ['2x', '3x', '5x', '10x', '20x'];
        const index = levels.indexOf(level);

        for (let i = 0; i < index; i++) {
            if (!miners[levels[i]]) {
                return false;
            }
        }
        return true;
    };

    return (
        <div className="miners-page">
            <div className="tap-count">
                Tap Count: {tapCount}
            </div>
            <div className="miners-container">
                {minerData.map((miner, index) => (
                    <div key={index} className={`miner-item ${miners[miner.level] ? 'bought' : ''}`}>
                        <img
                            src={`/images/miner_${miner.level}.png`}
                            alt={`Miner ${miner.level}`}
                            className="miner-image"
                        />
                        <div className="miner-details">
                            <span>Miner {miner.level}</span>
                            <button
                                onClick={() => handleBuyClick(miner.level, miner.price)}
                                disabled={miners[miner.level] || !canBuyMiner(miner.level)}
                                className="buy-button"
                            >
                                {miners[miner.level] ? 'Bought' : `Buy (${miner.price})`}
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MinersPage;
