import React, { useState, useEffect } from 'react';
import './Counter.css';

interface CounterProps {
    onIncrement: () => void;
    tapCount: number;
}

const Counter: React.FC<CounterProps> = ({ onIncrement, tapCount }) => {
    const [dailyLimit] = useState(24000);
    const [hourlyLimit] = useState(1000);
    const [dailyProgress, setDailyProgress] = useState(0);
    const [hourlyProgress, setHourlyProgress] = useState(0);

    useEffect(() => {
        // Update progress bars on tap count change
        const updateProgress = () => {
            const hourlyProg = (tapCount / hourlyLimit) * 100;
            const dailyProg = (tapCount / dailyLimit) * 100;
            setHourlyProgress(hourlyProg > 100 ? 100 : hourlyProg);
            setDailyProgress(dailyProg > 100 ? 100 : dailyProg);
        };

        updateProgress();
    }, [tapCount, hourlyLimit, dailyLimit]);

    return (
        <div className="counter-container">
            <h1 className="counter-value">{tapCount}</h1>
            <img
                src="/images/tap_button.png"
                alt="Tap Me"
                className="counter-button"
                onClick={onIncrement}
            />

            <div className="progress-bars">
                <div className="progress-bar">
                    <div
                        className="progress"
                        style={{ width: `${hourlyProgress}%` }}
                    ></div>
                    <span className="progress-label">Hourly: {tapCount}/{hourlyLimit}</span>
                </div>
                <div className="progress-bar">
                    <div
                        className="progress"
                        style={{ width: `${dailyProgress}%` }}
                    ></div>
                    <span className="progress-label">Daily: {tapCount}/{dailyLimit}</span>
                </div>
            </div>
        </div>
    );
};

export default Counter;
