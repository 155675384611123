import React from 'react';
import './NavButtons.css';

interface NavButtonProps {
    name: string;
    icon: string;
    onClick: () => void;
}

const NavButton: React.FC<NavButtonProps> = ({ name, icon, onClick }) => {
    return (
        <div className="nav-button" onClick={onClick}>
            <img src={icon} alt={name} className="nav-icon" />
            <span className="nav-text">{name}</span>
        </div>
    );
};

interface NavButtonsProps {
    onNavigate: (screen: string) => void;
    username: string; // Add this line
}

const NavButtons: React.FC<NavButtonsProps> = ({ onNavigate, username }) => {
    return (
        <div className="nav-buttons">
            <NavButton name="Invite" icon="/images/invite.png" onClick={() => onNavigate('Invite')} />
            <NavButton name="Daily Tasks" icon="/images/task.png" onClick={() => onNavigate('DailyTasks')} />
            <NavButton name="Home" icon="/images/home.png" onClick={() => onNavigate('Home')} />
            <NavButton name="Booster" icon="/images/booster.png" onClick={() => onNavigate('Booster')} />
            <NavButton name="Miners" icon="/images/miners.png" onClick={() => onNavigate('Miners')} />
            <NavButton name={`Profile (${username})`} icon="/images/profile.png" onClick={() => onNavigate('Profile')} />
        </div>
    );
};

export default NavButtons;
