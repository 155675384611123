import React, { useState } from 'react';
import './Login.css';
import axios from 'axios';

interface LoginProps {
    onLogin: (username: string) => void;
}

const Login: React.FC<LoginProps> = ({ onLogin }) => {
    const [username, setUsername] = useState<string>('');
    const [error, setError] = useState<string | null>(null);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (username) {
            try {
                // Call the login endpoint and handle response
                const response = await axios.post('http://localhost:5000/login', { username }, { params: { _: new Date().getTime() } });

                if (response.data) {
                    onLogin(username); // Notify the parent component of successful login
                } else {
                    setError('Login failed. Please try again.');
                }
            } catch (error) {
                console.error('Error logging in:', error);
                setError('An error occurred during login. Please try again.');
            }
        } else {
            setError('Username cannot be empty.');
        }
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <div className="profile-picture">
                    <img src="/images/profile.png" alt="Profile" />
                </div>
                <h1>Create your username</h1>
                <form onSubmit={handleSubmit} className="login-form">
                    <label>
                        <input
                            type="text"
                            value={username}
                            placeholder="Enter username"
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </label>
                    <button type="submit">Login</button>
                </form>
                {error && <p className="error-message">{error}</p>}
            </div>
        </div>
    );
};

export default Login;
