// src/components/ProfileScreen.tsx
import React from 'react';
import './ProfileScreen.css';

interface ProfileScreenProps {
    username: string;
    tapCount: number;
    boosters: { [key: string]: boolean };
    miners: { [key: string]: boolean };
}

const ProfileScreen: React.FC<ProfileScreenProps> = ({ username, tapCount, boosters, miners }) => {
    const totalTapSpeed = Object.keys(boosters).reduce((acc, key) => (boosters[key] ? acc + parseInt(key) : acc), 1) +
        Object.keys(miners).reduce((acc, key) => (miners[key] ? acc + parseInt(key) : acc), 0);

    return (
        <div className="profile">
            <h2>Profile</h2>
            <div className="profile-grid">
                <div className="profile-item">
                    <span className="profile-label">Username:</span>
                </div>
                <div className="profile-item">
                    <span className="profile-value">{username}</span>
                </div>
                <div className="profile-item">
                    <span className="profile-label">Total Tap Count:</span>
                </div>
                <div className="profile-item">
                    <span className="profile-value">{tapCount}</span>
                </div>
                <div className="profile-item">
                    <span className="profile-label">Boosters Owned:</span>
                </div>
                <div className="profile-item">
                    <span className="profile-value">{Object.keys(boosters).filter(key => boosters[key]).join(', ') || 'None'}</span>
                </div>
                <div className="profile-item">
                    <span className="profile-label">Miners Owned:</span>
                </div>
                <div className="profile-item">
                    <span className="profile-value">{Object.keys(miners).filter(key => miners[key]).join(', ') || 'None'}</span>
                </div>
                <div className="profile-item">
                    <span className="profile-label">Total Tap Speed per Tap:</span>
                </div>
                <div className="profile-item">
                    <span className="profile-value">{totalTapSpeed}</span>
                </div>
            </div>
        </div>
    );
};

export default ProfileScreen;
